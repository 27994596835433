@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
    font-family: 'NeueHaas';
    font-style: normal;
    font-weight: 400;
    src: url('/fonts/NeueHaasDisplayRoman.ttf');
}

@font-face {
    font-family: 'NeueHaas';
    font-style: normal;
    font-weight: 600;
    src: url('/fonts/NeueHaasDisplayMedium.ttf');
}

@font-face {
    font-family: 'NeueHaas';
    font-style: normal;
    font-weight: bold;
    src: url('/fonts/NeueHaasDisplayBold.ttf');
}


@layer base {
    html {
        height: 100%;
        background: white;
    }

    body {
        height: 100%;
        /*overflow:hidden;*/
        @apply font-sans;
    }

    p {
        @apply font-normal
    }
    h1 {
        @apply text-4xl font-bold mb-8
    }
    h2 {
        @apply text-3xl mb-6
    }
    h3 {
        @apply text-2xl
    }
    h4 {
        @apply text-xl
    }
    .glass-level-4{
        @apply bg-clip-padding backdrop-filter backdrop-blur-xl bg-opacity-10 border border-gray-200 border-opacity-20 bg-white shadow-lg
    }
    .glass-level-3{
        @apply bg-clip-padding backdrop-filter backdrop-blur-xl bg-opacity-20 border border-gray-200 border-opacity-30 bg-white shadow-lg
    }
    .glass-level-2{
        @apply bg-clip-padding backdrop-filter backdrop-blur-xl bg-opacity-40 border border-gray-200 border-opacity-40 bg-white shadow-lg
    }
    .glass-level-1{
        @apply bg-clip-padding backdrop-filter backdrop-blur-xl bg-opacity-60 border border-gray-200 border-opacity-0 bg-white shadow-lg
    }


}



p {
    letter-spacing: 0.2px;
}

label{
    font-weight: 500 !important;
}

input {
    letter-spacing: 0.2px;
}
