.to-rescribe {
    --tw-gradient-to: #223ff8;
    --tw-gradient-from: #7f3ab7;
}

.text-rescribe{
    color: #05178a;
}

.bg-rescribe-purple{
    background-color: #7f3ab7;
}

.border-rescribe{
    border-color: #05178a;
}

.bg-rescribe{
    background-color: #05178a;
}

.bg-rescribe-50{
    background-color: rgba(5, 23, 138, 0.06);
}

.ql-container{
    border: none !important;
}

.ql-editor{
    padding: 30px 0 !important;
}

.ql-toolbar{
    border: none !important;
    border-radius: 7px;
    box-shadow: 0 0px 6px -1px rgb(0 0 0 / 0.2), 0 2px 4px -2px rgb(0 0 0 / 0.1);
}

.noSelect {
    -webkit-tap-highlight-color: transparent;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}
.noSelect:focus {
    outline: none !important;
}

html {
    scroll-behavior: smooth !important;
}

/* For Webkit-based browsers (Chrome, Safari and Opera) */
.scrollbar-hide::-webkit-scrollbar {
    display: none;
}

/* For IE, Edge and Firefox */
.scrollbar-hide {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
}

.hide-focus:focus {
    border-color: inherit;
    -webkit-box-shadow: none;
    box-shadow: none;
}


